import {
  FocusEventHandler, ReactElement, useEffect, useState,
} from 'react'

import RemoveIcon from '@mui/icons-material/Remove'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'

import getCSSValue from 'helpers'
import { SubArea } from 'objects/types/protections'

type Props = {
  subarea?: SubArea;
  onBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onRemove: () => void;
  shouldReset: boolean;
  resetId: () => void;
}

const defaultProps = {
  subarea: undefined,
}

export default function SubAreaLine({
  subarea, onBlur, onRemove, shouldReset, resetId,
}: Props): ReactElement {
  const [value, setValue] = useState(subarea?.label || '')

  useEffect(() => {
    if (shouldReset) {
      setValue(subarea?.label || '')
      resetId()
    }
  }, [shouldReset])

  return (
    <div className="subarea-wrapper">
      <div style={{ flex: 1 }}>
        <TextField
          fullWidth
          variant="filled"
          InputProps={{
            disableUnderline: true,
          }}
          onChange={evt => setValue(evt.target.value)}
          value={value}
          onBlur={onBlur}
        />
      </div>
      <div className="d-flex align-items-center justify-content-center">
        <IconButton onClick={onRemove}>
          <RemoveIcon htmlColor={getCSSValue('--color-carbone')} />
        </IconButton>
      </div>
    </div>
  )
}

SubAreaLine.defaultProps = defaultProps
